.wrapper {
    display: flex;
    flex-direction: column;
}

.bar {
    display: flex;
    flex-shrink: 1;
    gap: 1px;

    margin-top: .25rem;
    background: #323232;
    font-size: 10pt;
}

.bar button {
    border-radius: 0px;
    background: #202020;
    border: none;
    color: #d5d5d5;
}

.bar button:hover {
    color: white;
    background: black;
}

.separator {
    flex-grow: 1;
}

.json-status {
    display: flex;
    gap: .5rem;
    padding: 0 .5rem;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10pt;
    user-select: all;
}
.json-status.ok {
    color: green;
}
.json-status.error {
    color: #f74646;
}
