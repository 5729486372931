.iconButton {
    height: fit-content;
    background-color: var(--bg-color) !important;
    color: var(--icon-color) !important;
    border: 1px solid #d8d8d8 !important;
}

.iconButton[data-active=true] {
    background-color: var(--active-bg-color) !important;
    color: var(--active-icon-color) !important;
}
