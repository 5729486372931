.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(191, 191, 191, 0.68);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1011;
}

.frame {
  position: relative;
  width: 90%;
  max-width: 880px;
  background-color: #373a3c;
  border-radius: 6px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: rgb(199, 199, 199);
  overflow: auto;
  max-height: 90%;
  direction: ltr
}

.closeButton {
  position: absolute;
  top: 4px;
  right: 4px;
}

.okButton {
  text-align: right;
}

.body {
  padding: 24px;
}
