.main {
  width: 100%;
  padding: 8px;
}

.copyicon {
  /* Define your default styles */
  color: inherit; /* or any other color */
  font-size: 12px; /* Adjust icon size */

  /* Add transition for smooth hover effect */
  transition: color 0.1s ease;
  position: relative; /* Enable positioning */
  top: -3px; /* Move the icon up by 3 pixels */
  left: 3px; /* Move the icon to the right by 3 pixels */
}

.copyicon:hover {
  /* Define styles for hover state */
  color: rgb(75, 140, 244); /* Change color on hover */
  cursor: pointer; /* Change cursor to indicate it's clickable */
}

.headertxt {
  padding: 12px 6px;
  font-size: 1.3em;
  font-weight: 300;
}

.main td,
.main th {
  padding: 8px 8px;
  vertical-align: top;
  /* border: 1px solid rgb(230, 230, 230); */
}

.main .list td {
  padding: 3px 8px;
}

.main.firstItemBold tr:first-child {
  font-weight: bold;
}

.main td:first-child {
  font-weight: 300;
  opacity: 0.5;
  font-size: 0.9em;
  white-space: nowrap;
  width: 136px;
}

/* .main td {
  vertical-align: top;
} */

.link {
  text-decoration: underline;
  display: inline-block
}

.link:hover {
  text-decoration: underline;
  color: #007bff;
  cursor: pointer;
}

tbody tr {
  border-bottom: 0px solid lightgrey;
}
