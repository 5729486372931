/* Maybe make components for this later */

.form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;
    gap: .75rem;
}

.form .form-field {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    font-size: 11pt;
}

.form .form-field > span {
    display: inline-flex;
    font-weight: 600;
}

.form .form-field.checkbox {
    display: inline-flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;

    user-select: none;
}

.form .form-field.checkbox input[type=checkbox] {
    margin-top: 2px; /* better alignment */
}
