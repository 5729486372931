.popup-content {
    display: flex;
    font-family: 'Exo 2 Variable', sans-serif;
}

.sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    max-width: 300px;
    padding-top: .25rem;

    user-select: none;

    background: #e6eaf7;

    box-shadow: 3px 3px 3px rgba(38, 64, 170, 0.1),
                1px 2px 2px rgba(38, 64, 170, 0.2);
}

.section-header {
    display: flex;
    align-items: center;
    padding: .25rem .25rem .25rem 1rem;
    gap: .5rem;
}

.section-header > span {
    flex-grow: 1;

    font-weight: 600;
    font-size: 9pt;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #50596a;
}

.sidebar .items {
    display: flex;
    flex-direction: column;
    gap: .25rem;
    padding: .25rem .5rem;

    max-height: 80vh;
    overflow-y: auto;
    scrollbar-width: thin;
}

.sidebar .item {
    display: flex;
    gap: .5rem;
    align-items: center;
    padding: .5rem .5rem;
    font-size: 11pt;

    white-space: nowrap;

    color: #2e2e2e;
    border: none;
    background: none;

    text-align: left;
    border-radius: 5px;
}
.sidebar .item > span {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
}
.sidebar a.item {
    text-decoration: none;
}
.sidebar .item:hover {
    background: #d7dae6;
}
.sidebar .item.active {
    background: white;
}

.tool-bar {
    display: inline-flex;
    align-items: center;
}

.tool-bar button {
    display: inline-flex;
    gap: .6em;
    align-items: center;

    padding: .4em .8em;

    font-size: 10pt;
    border: none;
    border-radius: 5px;
    background: none;

    color: #383a4c;
}

.tool-bar button:active {
    opacity: 0.7;
}

.tool-bar button.square {
    padding: .4em;
}

.tool-bar button:hover {
    background: #ced2dd;
}

.on-hover .tool-bar {
    opacity: 0;
}

.on-hover:hover .tool-bar {
    opacity: 1
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.content .form-content {
    max-height: calc(100vh - 200px);
    overflow: auto;
}

.footer {
    display: flex;
    gap: .5rem;
    align-self: flex-end;
    justify-content: flex-end;

    padding: .75rem;
}
