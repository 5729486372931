div.buttonsContainer[data-custom-styling=true] {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: var(--button-container-padding);
}

.button[data-custom-styling=true],
div.buttonsContainer[data-custom-styling=true] > .button {
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  border-radius: var(--button-border-radius);
  color: var(--text-color);
  background-color: var(--background-color) !important;
}

.button[data-custom-styling=true][data-has-button-shadow=false],
div.buttonsContainer[data-custom-styling=true] > .button[data-has-button-shadow=false] {
  box-shadow: none;
}

div.buttonsContainer[data-custom-styling=true] > .button + .button {
  margin-top: var(--margin-between-buttons);
}
