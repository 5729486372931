.main {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px;
  /* position: relative; */
}

.main input {
  border-radius: 20px;
  min-width: 280px;
  padding-right: 32px;
}

.inputContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.inputContainer img {
  position: absolute;
  right: 9px;
  width: 22px;
  margin-left: 12px;
}

.inputContainer.threeColorTemplate img {
  position: absolute;
  right: 1.6em;
  width: 22px;
  margin-left: 12px;
}

.items {
  position: fixed;
  /* left: 0; */
  background-color: white;
  /* padding: 6px 0; */
  border: 1px solid #d8d8d8;
  border-radius: 7px;
  z-index: 200;
  max-height: 400px;
  overflow-y: auto;
  margin: 44px 0 4px 0;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.2);
  min-width: 232px;
}

.item {
  padding: 4px 9px;
  cursor: pointer;
}

.selected {
  /* color: #007bff; */
  background-color: rgb(233, 233, 233);
}

.item:hover {
  /* text-decoration: underline; */
  /* color: #007bff; */
  background-color: rgb(233, 233, 233);
}

.noResults {
  position: absolute;
  background-color: white;
  /* padding: 6px 0; */
  border: 1px solid #d8d8d8;
  border-radius: 7px;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 44px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
  min-width: 232px;
  padding: 4px 9px;
  color: lightgrey;
  font-size: 0.9em;
  font-style: italic;
}

.headertxt {
  margin-right: 10px;
}
