.wrapper {
  display: flex;
  --threeColorTemplate-primary-action: hsl(105,55%,44%);
  --threeColorTemplate-highlight: hsl(212,100%,29%);
  --threeColorTemplate-background: hsl(202,41%,60%);
}

label > .settingsCheckbox {
 position: relative;
 margin-left: 0;
 margin-right: 1ch;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
  background-color: #f7f7f7;
  overflow: auto;
  flex: 1;
}

.main {
  flex: 1;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0px 0px 0px;
  /*padding: 64px 0px 0px ;*/
  width: 100%;
  overflow: auto;

  background-color: #f7f7f7;
}

@media (max-width: 479px) {
  .main {
    padding: 64px 0 12px;
  }
}

.main > div:last-child {
  flex: 1;
}

.editButton {
  position: fixed;
  top: 1px;
  right: 2px;
  cursor: pointer;
  font-size: 0.8em;
  z-index: 2;
}

.areaContainer2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  margin-top: 0px;
}

.areaContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: unset;
  overflow: auto;
  margin-top: 0px;
  height: 100%;
}

.infoDialogueContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4em;
}

.configButton + .appVariables {
  display: none;
}

.appVariables:hover,
.configButton:hover + .appVariables {
  display: unset;
  position: absolute;
  background-color: #000000c7;
  color: white;
  z-index: 1337;
  margin: 1rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 1.2rem;
  overflow: scroll;
}
