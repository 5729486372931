
.deleteIcon {
  padding: 0.4rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.4rem;
}

.deleteIcon:hover {
  color: #0056b3;
}
