.mainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
  background-color: #f7f7f7;
}

.main {
  flex: 1;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 64px 12px 12px;
}

.content {
  max-width: 750px;
  margin: 0 auto;
  padding: 48px 0;
}

.title {
  padding: 12px;
  font-size: 1.8em;
  font-weight: 300;
}
.titleText {
  width: 550px;
}

.viewer {
  gap: 0.3em;
  white-space: nowrap;
  align-items: center;
  padding: 12px;
  /* border-bottom: 1px solid #e2e2e2; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin: 6px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
}

.viewer:hover {
  background-color: #f3f3f3;
}
.viewerDisabled {
  background-color: #f3f3f3;
}

.viewer:first-child {
  /* border-top: 1px solid #e2e2e2; */
}

.noViewerMessage {
  font-size: 0.9em;
  opacity: 0.5;
  padding: 12px;
}

.viewerIcons {
  display: flex;
  align-items: center;
}

.viewerIcon {
  margin-right: 8px;
  opacity: 0.6;
  position: relative;
}

.viewerIcon::before {
  content: attr(data-tooltip);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  pointer-events: none;
  white-space: nowrap;
}

.viewerIcon:hover::before {
  opacity: 1;
  transition-delay: 0.1s;
}