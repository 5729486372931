.login-app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.login-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    background-color: #eaeaea;
    gap: 200px;
}

.login-main {
    position: relative;
    flex-shrink: 1;
    width: min(100vw, 500px);
    background-color: white;
    border-radius: 8px;
    padding: 3rem;
    font-family: Exo, sans-serif;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 64px; /* add header height so that this is centered */
}

.login-main h1 {
    font-size: 22pt;
    font-weight: 600;
}

.login-main form {
    padding-top: 1rem;
}

.login-main form label {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 11pt;
    gap: .25em;
    margin-bottom: .75rem;
}

.login-main form label span {
    font-weight: 500;
}

.login-main form input {
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    background: white;
    color: black;
    padding: .5em 1em;
}

.login-main input[type=password] {
    -webkit-text-security: none;
    input-security: none;
}

.login-button-bar {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: 1rem;
}

.login-button-bar .login-error {
    flex-grow: 1;
    color: #f13f3f;
}

.login-button-bar .login-spinner {
    flex-grow: 1;
}

.login-button-sso > img {
    height: 32px;
}
.login-button-sso > span {
    display: flex;
}